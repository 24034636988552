<template>

  <div>
    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>
    <!-- Table Container Card -->
    <b-card no-body>
      <b-card-body>
        <b-row>
            <b-col cols="12" md="4" class="mb-md-0 mobile_tab_max_width_flex">
                <b-form-select v-model="siteData" @change="filterTable();">
                  <b-form-select-option value="" disabled>Select</b-form-select-option>
                  <b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name | capitalize}}</b-form-select-option>
                </b-form-select>
            </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mobile_tab_max_width_flex mobile_margin_bottom_1rem"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="filterTable"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
            class="mobile_tab_max_width_flex all_btn_tab"
          >
            <div class="d-flex align-items-center justify-content-end mobile-view mobile_tab_display_block">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1 mobile-margin-bottom mobile-margin-input mobile_margin_bottom_1rem  "
                placeholder="Search..."
                @input="filterTable"
              />
              <b-button
                v-if="checkPermission($route.name, 'Add User')"
                variant="warning"
                :to="{ name: 'add-email-user' }"
                class=""
              >
                <span class="text-nowrap">Add User</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>
      <b-table sticky-header
        ref="refUserListTable"
        class="position-relative sTable common_big_tbl"
        :items="dataList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :class="items.length <= 10 ? 'remove_overflow' : 'add_overflow'"
      >

        <!-- Column: User -->

        <template #cell(full_name)="items">
           
            <span class="wordBreak">
              <div class="my-1">{{ items.item.full_name | capitalize}}</div>
            </span>
         
        </template>


        <template #cell(email)="items">
           
            <span class="wordBreak">{{ items.item.email}}</span>
         
        </template>

        <!-- Column: Role -->
        <template #cell(types)="items">
           
            <span class="wordBreak">{{ items.item.email_frequency | filterFrequency }}</span>
         
        </template>
        
        <template #cell(report)="items">           
            <span class="wordBreak">{{ getReport(items.item.report_breakdown) }}</span>         
        </template>
        
        
        <template #cell(status)="items">
           
            <b-badge
              pill
              :variant="variantColor(items.item.status)"
              class="text-capitalize"
            >
              {{ items.item.status}}
            </b-badge>
         
        </template>

        <template #cell(created_at)="items">
           
            <span>{{ items.item.created_at | dateTime}}</span>
         
        </template>

        <template #cell(actions)="items">
          <!-- <b-dropdown
            v-if="checkPermission($route.name, 'Edit') || checkPermission($route.name, 'Change Status') || checkPermission($route.name, 'Delete')"
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item v-if="checkPermission($route.name, 'Edit')" :to="{ name: 'edit-email-user', params: { id: items.item._id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="checkPermission($route.name, 'Change Status')" @click="statusChange(items.item)">
              <feather-icon :icon="items.item.status == 'active' ? 'UserMinusIcon' : 'UserCheckIcon'" />
              <span class="align-middle ml-50" v-if="items.item.status == 'active'">Inactive</span>
              <span class="align-middle ml-50" v-if="items.item.status == 'inactive'">Active</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="checkPermission($route.name, 'Delete')" @click="deleteItem(items.item._id)">
              <feather-icon icon="Trash2Icon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>

            
            
          </b-dropdown>  -->

          <b-link v-if="checkPermission($route.name, 'Edit')" :to="{ name: 'edit-email-user', params: { id: items.item._id } }"
            v-b-tooltip.hover.v-warning
            title="Edit"
            variant="outline-warning"
          >
              <feather-icon icon="EditIcon" class="mediumSize mr-1"/>
              
          </b-link>

            <b-link v-b-tooltip.hover.v-warning :title= "items.item.status == 'active' ? 'Mark Inactive' : 'Mark Active'" variant="outline-warning" v-if="checkPermission($route.name, 'Change Status')" @click="statusChange(items.item)">
                <feather-icon :icon="items.item.status == 'active' ? 'CheckCircleIcon' : 'CircleIcon'" class="mediumSize mr-1" />	
            </b-link>

           <b-link v-if="checkPermission($route.name, 'Delete')" @click="deleteItem(items.item._id)"
            v-b-tooltip.hover.v-warning
            title="Delete"
            variant="outline-warning"
          >
              <feather-icon icon="Trash2Icon" class="mediumSize mr-1"/>
              
          </b-link>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRecords"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="filterTable"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,VBTooltip,
  BBadge, BDropdown, BDropdownItem, BPagination,BBreadcrumb, BCardBody,
  BFormSelect, BFormSelectOption
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCardBody,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BBreadcrumb,
    vSelect,
    BFormSelect,
    BFormSelectOption
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip
  },
  data() {
    return {
       tableColumns: [
            { key: 'full_name', label: 'Name', sortable: true , thStyle:  {width: '15%'}},
            { key: 'email', label: 'Email', sortable: true , thStyle:  {width: '28%'}},
            { key: 'report', label: 'Report', sortable: false , thStyle:  {width: '20%'}},
            { key: 'status', label: 'Status', sortable: true , thStyle:  {width: '5%'}},
            { key: 'created_at', label: 'Added On', sortable: true , thStyle:  {width: '15%'}},
            { key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '18%'}},
          ],

        sites:[],
        siteData:'',

        items:[],
        isSortDirDesc: true,
        perPageOptions: [10, 20, 50, 100],
        perPage : 10,
        sortBy : 'id',
        webUrl: process.env.VUE_APP_SERVER_URL,
        totalRecords : 0,
        currentPage : 1,
        searchQuery:'',
        from:null,
        to:null
    }
  },
  filters: {
    
    filterFrequency(item){
      var arr = [];

      item.forEach(element => {
        if (element.frequency != '') {

          if(element.name == 'QI'){
            arr.push('Quality Inspection');
          }else if(element.name == 'Daily Checklist'){
            arr.push('Checklist');
          }else{
            arr.push(element.name);
          }
        }
      })

      return arr.join(', ');
    }
  },
  methods : {
    getReport(data){
      var report = [];
      var flag = false;
      data.forEach(el => {
        if(el.email_custom_forms && el.email_custom_forms.length > 0 ){
          if(flag == false){
            report.push('Custom Form');
            flag = true;
          }
        } else {
          report.push(el.report_name)
        }
      });
      return report.join(', ');
    },

    dataList(){
      return this.$store.dispatch(POST_API, {
                   data:{
                     page: this.currentPage,
                     keyword: this.searchQuery,
                     rowsPerPage:this.perPage,
                     sortBy:this.sortBy,
                     sortDirection:this.isSortDirDesc,
                     role:this.$store.getters.currentUser.role,
                     om_sites:this.$store.getters.currentUser.om_sites,
                     site: this.siteData != '' ? this.siteData :null,
                   },
                   api: '/api/email-users'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.items = this.$store.getters.getResults.data.docs;
                        this.totalRecords = this.$store.getters.getResults.data.total;
                        this.perPage = this.$store.getters.getResults.data.limit;
                        this.from = this.$store.getters.getResults.data.from
                        this.to = this.$store.getters.getResults.data.to
                        return this.items;
                    }
                });
    },
    filterTable(){
      this.$refs.refUserListTable.refresh();
    },
    variantColor(status){
      if (status == 'active') {
        return 'success';
      }else{
        return 'primary';
      }
    },
    statusChange(item){
      if (item.status == 'active') {
          var msg = 'Are you sure want to inactivate this record?';
          var message='User Inactivated Successfully.';
      } 
      else{
          var msg = 'Are you sure want to activate this record?';
          var message='User Activated Successfully.';
      }

      Swal.fire({
                title: 'Please Confirm',
                text: msg,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
              })
            .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                    data:{
                          id: item._id,
                          status:item.status
                        },
                    api:"/api/change-email-user-status",
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              this.showAlert();
                          } else {
                            
                            Swal.fire({
                              icon: 'success',
                              title: message,
                              showConfirmButton: false,
                              timer: 1500
                            })
                            
                            this.$refs.refUserListTable.refresh();
                          }
                      });
              }
          })
          .catch(err => {
             
          })
    },

    deleteItem(id){
      var msg = 'Are you sure want to delete this record?';
      var message='User Deleted Successfully.';

      Swal.fire({
                title: 'Please Confirm',
                text: msg,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
              })
            .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                    data:{
                          id: id,
                          status:'deleted'
                        },
                    api:"/api/change-email-user-status",
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              this.showAlert();
                          } else {
                            
                            Swal.fire({
                              icon: 'success',
                              title: message,
                              showConfirmButton: false,
                              timer: 1500
                            })
                            
                            this.$refs.refUserListTable.refresh();
                          }
                      });
              }
          })
          .catch(err => {
              
          })
    },

    allSites(){
        return this.$store.dispatch(POST_API, {
            data:{
              role:this.$store.getters.currentUser.role,
              om_sites:this.$store.getters.currentUser.om_sites,
            },
            api: '/api/all-sites'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
            } else {
                this.sites = this.$store.getters.getResults.data;
                // if (this.sites.length == 1 ) {
                //     this.siteData = this.sites[0]._id;
                //     this.filterTable();
                //     this.updateDateFormat();
                // }
                var obj = {
                    _id:'all-site',
                    site_name:'All Project Sites',
                    pdf_date_format: 'DD MMM YYYY',
                    pdf_time_format: 'HH:mm'
                }
                this.sites.unshift(obj);
                
                this.siteData = this.defaultSite(this.sites)._id;
					      this.filterTable();

                return this.sites;
            }
        });
    },

    breadCrumb(){
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to:null,
        text: 'Email Listing',
      },{
        to:null,
        text:'Email Users',
        active:true
      }];
      return item;
    }
  },

  mounted(){
      this.allSites();
  }
  
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
